import React from 'react'
import { Link } from 'gatsby'
import headerStyles from './header.module.css'
import logo from '../assets/logo.png'

export default class Header extends React.PureComponent {
  constructor() {
    super()
    this.state = { isNavPastHero: false }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const isNavPastHero = window.innerHeight * 0.45 - 90 < window.scrollY
    this.setState(() => ({ isNavPastHero }))
  }

  render = () => (
    <div
      className={`${headerStyles.nav} ${
        this.state.isNavPastHero ? headerStyles.navSolidBackground : ''
      }`}
    >
      <div className={headerStyles.navInner}>
        <Link to="/" title="Home Page">
          <img src={logo} className={headerStyles.logo} />
        </Link>
        <div className={headerStyles.container}>
          <Link to={'services'} className={headerStyles.link}>
            Services
          </Link>
          <Link to={'projects'} className={headerStyles.link}>
            Projects
          </Link>
          <Link to={'gallery'} className={headerStyles.link}>
            Gallery
          </Link>
          <Link to={'about'} className={headerStyles.link}>
            About
          </Link>
          <Link to={'contact'} className={headerStyles.link}>
            Contact
          </Link>
        </div>
      </div>
    </div>
  )
}
