import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

import Header from './header'
import './layout.css'

import { slide as Menu } from 'react-burger-menu'
import { StaticQuery, graphql } from 'gatsby'

class Layout extends React.Component {
  constructor() {
    super()
    this.contentRef = React.createRef()
  }

  render() {
    const onNavigateClick = () => {
      // Find first focusable element in main content and focus it
      this.contentRef.current
        .querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )[0]
        .focus()
    }

    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
              }
            }
            hero: file(relativePath: { eq: "hero.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 2500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: `NSW Civil has a combined experience of over 25 years, we have worked in
                various areas within plumbings and drainage. We have vast experience
                across residential, commercial, high-rise and civil.`,
                },
                { name: 'keywords', content: 'nsw, civil, plumbing, drainage' },
              ]}
            />
            <button
              className="navigateToContentButton"
              onClick={onNavigateClick}
            >
              Navigate to content
            </button>
            <div id="outer-container">
              <Menu
                outerContainerId={'outer-container'}
                pageWrapId={'page-wrap'}
                right
              >
                <Link className="menu-item" to={'/'}>
                  Home
                </Link>
                <Link className="menu-item" to={'services'}>
                  Services
                </Link>
                <Link className="menu-item" to={'projects'}>
                  Projects
                </Link>
                <Link className="menu-item" to={'gallery'}>
                  Gallery
                </Link>
                <Link className="menu-item" to={'about'}>
                  About
                </Link>
                <Link className="menu-item" to={'contact'}>
                  Contact
                </Link>
              </Menu>
              <div id="page-wrap">
                <Header />
                <Img
                  fluid={data.hero.childImageSharp.fluid}
                  style={{
                    height: '45vh',
                    marginBottom: '0',
                    marginTop: '-90px',
                  }}
                />
                <div
                  style={{
                    margin: '15px 0',
                    padding: '0px 1.0875rem 1.45rem',
                    paddingTop: 0,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    ref={this.contentRef}
                    style={{
                      width: 960,
                      flex: '0 1 auto',
                      minWidth: 0,
                    }}
                  >
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.any,
}

export default Layout
