import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const AboutPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Layout>
    <h1 style={{ paddingLeft: '20px' }}>{edges[0].node.frontmatter.title}</h1>
    <article dangerouslySetInnerHTML={{ __html: edges[0].node.html }} />
  </Layout>
)

AboutPage.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fields: { collection: { eq: "about" } } }) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default AboutPage
